import React from 'react';
import './App.css';

import { AnchorButton, Button, ButtonGroup } from "@blueprintjs/core";

function App() {
  return (
    <div className='centered'>
        <h1>Word Tracker</h1>
        <p>Coming Soon...</p>
        <ButtonGroup minimal={true}>
        <AnchorButton
          text="GitHub"
          icon="git-repo"
          href="https://github.com/LeoVen"
          target={"_blank"}
          rel="noreferrer"
        />
      </ButtonGroup>
    </div>
  );
}

export default App;
